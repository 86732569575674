import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <h1>We're hunting customers!</h1>
    <p>If you are looking for:</p>
    <ul>
      <li>Build an awesome product</li>
      <li>Outsource your IT department</li>
      <li>Hire a Chief Technology Officer (CTO) as a Service</li>
      <li>Get Software Engineering consultancy in AWS and/or Kubernetes</li>
    </ul>
    <p>Then we may be your key strategic partner! Contact us at <a href="mailto:hello@desarrollointeractivo.com?subject=Contact form: Subject&amp;body=Name:%0D%0AJob Title:%0D%0APhone:%0D%0A%0D%0AI'm looking for:%0D%0A%0D%0A">hello@desarrollointeractivo.com</a></p>
  </Layout>
)

export default IndexPage
